const contactSection = document.getElementsByClassName('contact-section')[0];

if (contactSection) {
  const filtersArr = contactSection.querySelectorAll('.filters-slider__item');
  const formsArr = contactSection.querySelectorAll('.contact-section__form');

  filtersArr.forEach((filter, i) => {
    filter.addEventListener('click', () => {
      for (let j = 0; j < filtersArr.length; j++) {
        filtersArr[j].classList.remove('active');
        formsArr[j].classList.remove('active');
      }
      filter.classList.add('active');
      formsArr[i].classList.add('active');
    });
  });
}

const officesSection = document.getElementsByClassName('offices-section')[0];

if (officesSection) {
  const filtersArr = officesSection.querySelectorAll('.filters-slider__item');
  const titlesArr = officesSection.querySelectorAll(
    '.offices-section__title-item'
  );
  const itemsArr = officesSection.querySelectorAll('.offices-section__item');

  filtersArr.forEach((filter, i) => {
    filter.addEventListener('click', () => {
      for (let j = 0; j < filtersArr.length; j++) {
        titlesArr[j].classList.remove('active');
        filtersArr[j].classList.remove('active');
        itemsArr[j].classList.remove('active');
      }
      titlesArr[i].classList.add('active');
      filter.classList.add('active');
      itemsArr[i].classList.add('active');
    });
  });
}
