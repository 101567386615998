import Swiper, { Autoplay, Navigation, EffectCreative } from 'swiper';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let sectorsSwiperWr = document.querySelectorAll('.sectors-section__slider');
sectorsSwiperWr.forEach((el) => {

  let breakpoints = {
    551: {
      spaceBetween: 18,
      slidesPerView: 2,
    },
    651: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
  };

  if(el.classList.contains('catalyst-type')) {
    breakpoints = {
      300: {
        spaceBetween: 20,
        slidesPerView: 1,
      },
      651: {
        spaceBetween: 20,
        slidesPerView: 1.5,
      },
    }
  }

  if (el) {
    let swiperEl = el;
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    swiperOnResize('(max-width: 551px)', swiperEl,
        {
          modules: [Autoplay, Navigation],
          slidesPerView: 1.5,
          spaceBetween: 20,
          threshold: 10,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          },
          navigation: {
            prevEl: swiperEl.querySelector('.slider-buttons .slider-btn.prev'),
            nextEl: swiperEl.querySelector('.slider-buttons .slider-btn.next'),
          },
          breakpoints: breakpoints,
        },
    );
  }
});
