import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

if (document.getElementsByClassName('teams-section__slider')[0]) {
  const teamsSlidersArr = document.querySelectorAll('.teams-section__slider');
  teamsSlidersArr.forEach((sliderEl, sliderIdx) => {
    /*const slides = sliderEl.querySelectorAll('.swiper-slide');
    let loop = false;
    let loopedSlides = false;

    if (slides.length >= 4) {
      loop = true;
      loopedSlides = slides.length * 4;
    }*/

    let pauseOnMouseEnter = window.innerWidth > 1024;

    let breakpoints = {
      451: {
        spaceBetween: 16,
        slidesPerView: 2,
      },
      651: {
        spaceBetween: 16,
        slidesPerView: 3,
      },
      901: {
        spaceBetween: 16,
        slidesPerView: 3,
      },
      1025: {
        spaceBetween: 20,
        slidesPerView: 4,
      },
      1300: {
        spaceBetween: 30,
        slidesPerView: 4,
      },
    }

    if (sliderEl.classList.contains('big-card-type')) {
      breakpoints = {
        451: {
          spaceBetween: 16,
          slidesPerView: 1.2,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 1.5,
        },
        1025: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        1300: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
      }
    }

    if (sliderEl.classList.contains('equities-type')) {
      breakpoints = {
        451: {
          spaceBetween: 16,
          slidesPerView: 1.2,
        },
        651: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        1020: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
        1300: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
      }
    }

    const teamsSwiper = new Swiper(sliderEl, {
      modules: [Autoplay, Navigation],
      slidesPerView: 1,
      spaceBetween: 20,
      threshold: 10,
      /*loop: loop,
      loopedSlides: loopedSlides,*/
      autoplay: {
        delay: 3200,
        disableOnInteraction: false,
        pauseOnMouseEnter: pauseOnMouseEnter,
      },
      navigation: {
        prevEl: sliderEl.querySelector('.slider-buttons .slider-btn.prev'),
        nextEl: sliderEl.querySelector('.slider-buttons .slider-btn.next'),
      },
      breakpoints: breakpoints,
    });

    swiperObserver(teamsSwiper);
  });
}

let columnCount = 3;
let teamsList;

function initTeamsHub() {
  teamsList = document.querySelector('.teams-hub-section__second-list');

  if (teamsList) {
    console.log(columnCount)
    addOpenContentBlocks(teamsList, columnCount);

    const itemsArr = teamsList.querySelectorAll('.teams-hub-section__item');

    itemsArr.forEach((item) => {
      item.addEventListener('click', handleClick);
    });
  }
}

function handleClick(event) {

  if (document.querySelector('.teams-hub-section__item-open-content')) {
    const openElArr = document.querySelectorAll('.teams-hub-section__item-open-content');
    openElArr.forEach(openEl => {
      openEl.classList.remove('active');
    });
  }

  const clickedItem = event.currentTarget;

  let openFlag = true;

  if (clickedItem.classList.contains('active')) {
    clickedItem.classList.remove('active');
    openFlag = false;

    setTimeout(function () {
      openFlag = true;
    }, 100)
  } else {
    const itemsArr = teamsList.querySelectorAll('.teams-hub-section__item');
    itemsArr.forEach((item) => {
      item.classList.remove('active');
    });

    clickedItem.classList.add('active');
  }

  const contentToCopy = clickedItem.querySelector('.teams-hub-section__item-container').cloneNode(true);

  let nextOpenContentBlock = clickedItem.nextElementSibling;
  while (nextOpenContentBlock && !nextOpenContentBlock.classList.contains('teams-hub-section__item-open-content')) {
    nextOpenContentBlock = nextOpenContentBlock.nextElementSibling;
  }

  if (nextOpenContentBlock) {
    nextOpenContentBlock.innerHTML = '';
    nextOpenContentBlock.appendChild(contentToCopy);

    if (openFlag) {

      nextOpenContentBlock.classList.add('active');

    } else {
      nextOpenContentBlock.classList.remove('active');
    }
  }
}

function destroyTeamsHub() {
  if (teamsList) {
    const itemsArr = teamsList.querySelectorAll('.teams-hub-section__item');

    itemsArr.forEach((item) => {
      item.removeEventListener('click', handleClick);
    });

    if (document.querySelector('.teams-hub-section__item-open-content')) {
      const openElArr = document.querySelectorAll('.teams-hub-section__item-open-content');
      openElArr.forEach(openEl => {
        openEl.remove();
      });
    }

    teamsList = null;
  }
}
/*
function addOpenContentBlocks(teamsList, columnsCount) {
  if (teamsList) {
    const itemsArr = teamsList.querySelectorAll('.teams-hub-section__item');

    const rowsCount = Math.ceil(itemsArr.length / columnsCount);
    const lastRowCount = itemsArr.length % columnsCount;

    for (let i = 1; i < rowsCount; i++) {
      const openContentBlock = document.createElement('div');
      openContentBlock.classList.add('teams-hub-section__item-open-content');
      teamsList.insertBefore(openContentBlock, itemsArr[i * columnsCount - 1].nextSibling);
    }

    if (lastRowCount > 0) {
      const openContentBlock = document.createElement('div');
      openContentBlock.classList.add('teams-hub-section__item-open-content');
      teamsList.appendChild(openContentBlock);
    }
  }
}
*/

function addOpenContentBlocks(teamsList, columnsCount) {
  if (teamsList) {
    const itemsArr = teamsList.querySelectorAll('.teams-hub-section__item');

    const rowsCount = Math.ceil(itemsArr.length / columnsCount);

    for (let i = 1; i <= rowsCount; i++) {
      const openContentBlock = document.createElement('div');
      openContentBlock.classList.add('teams-hub-section__item-open-content');

      const insertPosition = i * columnsCount < itemsArr.length ? itemsArr[i * columnsCount - 1].nextSibling : null;

      if (insertPosition) {
        teamsList.insertBefore(openContentBlock, insertPosition);
      } else {
        teamsList.appendChild(openContentBlock);
      }
    }
  }
}

/*initTeamsHub();*/

function handleResize() {
  if (window.innerWidth <= 640) {
    columnCount = 1;
    destroyTeamsHub();
    initTeamsHub();
  } else if (window.innerWidth <= 1024) {
    columnCount = 2;
    destroyTeamsHub();
    initTeamsHub();
  } else if (window.innerWidth > 1024) {
    columnCount = 3;
    destroyTeamsHub();
    initTeamsHub();
  }
}

window.addEventListener('resize', handleResize);

handleResize();
